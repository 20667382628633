<template>
  <v-card
    flat
  >
    <v-card-title>
      {{ $vuetify.lang.t('$vuetify.healthReport.sport') }}
    </v-card-title>

    <v-responsive :aspect-ratio="16/9">
      <v-chart
        v-if="option"
        :options="option"
      />
    </v-responsive>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        hide-default-footer
      >

        <template v-slot:item.time="{ item }">
          {{ item.time | moment('MM-DD') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            color="info"
            small
            @click="handleShowItem(item)"
          >
            mdi-eye
          </v-icon>
        </template>

      </v-data-table>
    </v-card-text>

    <v-subheader>
      {{ $vuetify.lang.t('$vuetify.healthReport.analysisResult') }} :
    </v-subheader>


    <v-card-text>
      <div
        v-if="analysis"
      >
        {{ analysis }}
      </div>

      <div
        v-else
        class="text-center"
      >
        {{ $vuetify.lang.t('$vuetify.common.noData') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

  import 'echarts/lib/chart/line'

  export default {
    props: {
      datas: {
        type: Array,
        default: () => []
      },
      analysis: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        option: null,
        items: []
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.healthReport.time'),
            align: 'start',
            sortable: false,
            value: 'time',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.sport'),
            align: 'start',
            sortable: false,
            value: 'data',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.conclusion'),
            align: 'start',
            sortable: false,
            value: 'conclusion',
          }
        ]
      }
    },

    created () {
      this.initialize()

    },

    methods: {
      initialize() {
        this.datas.sort( (a, b) =>  {
          if (new Date(a) > new Date(b)) {
            return 1
          }
          if (new Date(a) < new Date(b)) {
            return -1
          }
          return 0
        } )

        this.items = this.datas

        this.items.sort( (a, b) => {
          if (a.time < b.time ) {
            return -1
          }
          if (a.time > b.time ) {
            return 1
          }
          return 0
        })

        let timeData = [], sportData = []

        this.datas.forEach( element => {
          timeData.push(element.time)

          if (element.data) {
            sportData.push(element.data)
          } else {
            sportData.push(0)
          }
        })

        this.option = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeData
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: this.$vuetify.lang.t('$vuetify.healthReport.sport'),
              type: 'line',
              data: sportData
            }
          ]
        }
      }
    }
  }
</script>

<style>
.echarts {
  width: 100%;
  height: 100%;
}
</style>
